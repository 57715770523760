/*===============================
=            Sliders            =
===============================*/

var Sliders = {

    init: function () {

        if (! $.fn.slick) {
            $('.js-slides').hide();
            return false;
        }

        $('.kv-item:hidden', '.js-keyvisual-slider').remove();

        // Animacija za prvi slider mora biti v callbacku eventa init
        /*$('.js-keyvisual-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
            if (slick.$slides) {
                var $currentSlide = $(slick.$slides[currentSlide]);
            }
        });*/

        $('.js-keyvisual-slider:visible').not('.slick-initialized').slick({
            dots: true,
            autoplay: true,
            autoplaySpeed: 7000,
            infinite: true,
            speed: 500,
            cssEase: 'linear',
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1
        });


        /*
        * BLACK FRIDAY kv
        * Je izven slider callbacka, ker je na mobile verziji brez.
        * */
        if($('.kv-blackfriday').length) {

            $(".deal.blue").hide().css('visibility','visible').delay(1000).show(200);
            $(".deal.orange").hide().css('visibility','visible').delay(1300).show(200);
            $(".deal.green").hide().css('visibility','visible').delay(1600).show(200);

            var countDownDate = new Date("Nov 30, 2023 10:00:00").getTime();
            var x = setInterval(function() {
                /* black friday counter show */
                if($('#countdownblackfriday').hasClass('invisible')) {
                    $('#countdownblackfriday').hide().removeClass('invisible').fadeIn();
                }
                var now = new Date().getTime();
                var distance = countDownDate - now;

                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor(distance % (1000 * 60) / 1000);

                if(days<10){
                    days="0"+days;
                }
                if(hours<10){
                    hours="0"+hours;
                }
                if(minutes<10){
                    minutes="0"+minutes;
                }
                if(seconds<10){
                    seconds="0"+seconds;
                }

                document.getElementById("countdownblackfriday").innerHTML = "<div>" + days + "<br/> <span>DNI</span> </div>" + "<div>:</div><div>" + hours + "<br/> <span>UR</span> </div>" + "<div>:</div><div>" + minutes + "<br/> <span>MINUT</span> </div>"+ "<div>:</div><div>" + seconds + "<br/> <span>SEKUND</span> </div>";

                if (distance < 0) {
                    clearInterval(x);
                    document.getElementById("countdownblackfriday").innerHTML = "AKCIJA JE POTEKLA";
                }
            }, 1000);

        }




        /**
         * First slider animation
         */
        if($('.js-keyvisual-slider').find('.kv-first').length) {

            // Animacija za slider "SSD gostovanje. Prvi v Sloveniji"
            if ($('.js-keyvisual-slider .kv-first').find('.loading-compare').length) {
                $('.loading-compare .loading-bar').barslide({
                    complete: function () {
                        this.siblings('i').removeClass('hidden').show();
                    }
                });
            }
        }


        /**
         * Slick - after slider change
         * In case of adding animation for first slider, use 'init' event
         */
        $('.js-keyvisual-slider').on('afterChange', function(event, slick, currentSlide) {
            if (slick.$slides) {
                var $currentSlide = $(slick.$slides[currentSlide]);

                // Animacija za slider "SSD gostovanje. Prvi v Sloveniji"
                if ($currentSlide.find('.loading-compare').length) {
                    $('.loading-compare .loading-bar').barslide({
                        complete: function () {
                            this.siblings('i').removeClass('hidden').show();
                        }
                    });
                }

                // Animacija za slider "Super hitri strežniki"
                if ($currentSlide.find('.kv-fast').length) {
                    var bw = ($(document.body).outerWidth() / 2) + 200;
                    setTimeout(function () {
                        var $srvImg = $currentSlide.find('.srv img');
                        $srvImg.css({
                            left: '-' + bw + 'px'
                        }).show();
                        $srvImg.velocity({
                            left: 0
                        }, 600, [300, 30]);
                    }, 1000);
                }

                // Animacija za slider "1000+ pohval"
                if ($currentSlide.find('.kv-quotes')) {
                    $('.speech-cloud.left', $currentSlide).animate({
                        opacity: 1
                    }, 800, function () {
                        $('.speech-cloud.right', $currentSlide).animate({
                            opacity: 1
                        }, 600);
                    });
                }

                // Animacija za slider "Noro poceni domene"
                if ($currentSlide.find('.kv-options').length) {
                    var opt = $currentSlide.find('.kv-options .option').each(function (i, e) {
                        $(this).delay((i++) * 150).css({
                            'margin-top': '-45px'
                        }).velocity({
                            opacity: 1,
                            'margin-top': 0
                        }, 500);
                    });
                }
            }
        });

        /* Slick - before slider change */
        $('.js-keyvisual-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
            if (slick.$slides) {
                var $currentSlide = $(slick.$slides[currentSlide]);
                var $nextSlide = $(slick.$slides.eq(nextSlide));

                // Pobarvaj "dots" navigacijo glede na ozadje trenutnega slida
                var $dots = slick.$dots.find('button');
                $dots.css({
                    'color' : $nextSlide.data('bg')
                });

                // Animacija za slider "SSD Gostovanje - Prvi v Sloveniji"
                if ($currentSlide.find('.loading-compare').length) {
                    $currentSlide.find('.green .loading-bar').fadeOut('slow', function () {
                        $(this).width(0).animate({
                            opacity: 1
                        }, 600);
                    });
                    $currentSlide.find('.red .loading-bar').fadeOut('slow', function () {
                        $(this).width(0).animate({
                            opacity: 1
                        }, 600);
                    });
                    $currentSlide.find('.right-side i').hide();
                }

                // Animacija za slider "Noro poceni domeni"
                if ($currentSlide.find('.kv-options').length) {
                    $currentSlide.find('.kv-options .option').velocity({
                        'opacity': 0
                    }, 800);
                }

                // Animacija za slider "Super hitri strežniki"
                if ($currentSlide.hasClass('kv-fast')) {
                    $currentSlide.find('.srv img').velocity({
                        left: bw + 'px'
                    }, 800);
                }

                // Animacija za slider "Več kot 1000+ pohval"
                if ($currentSlide.hasClass('kv-quotes')) {
                    $currentSlide.find('.speech-cloud').animate({
                        opacity: 0
                    });
                }
            }
        });

        var numberOfSlides = $('.js-promobox-slides').find('.domain-promo-box').count;
        if(numberOfSlides > 5) numberOfSlides = 5;

        $('.js-promobox-slides:visible').not('.slick-initialized').slick({
            autoplay: false,
            autoplaySpeed: 3000,
            infinite: false,
            speed: 500,
            cssEase: 'linear',
            slidesToShow: numberOfSlides,
            slidesToScroll: 1,
            adaptiveHeight: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 390,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplaySpeed: 2000,
                        centerMode: true
                    }
                }
            ]
        });

        $('.js-sidebar-slides:visible').not('.slick-initialized').slick({
            autoplay: true,
            autoplaySpeed: 2000,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            slidesPerRow: 1,
            fade: true,
            centerMode: true,
            adaptiveHeight: true,
            appendArrows: false
        });

        $('.js-testimonials:visible').not('.slick-initialized').slick({
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            slidesPerRow: 1,
            fade: true,
            centerMode: true,
            adaptiveHeight: true
        });

        $('.js-multi-slides:visible').not('.slick-initialized').slick({
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            slidesPerRow: 1
        });

        $('.js-slider-1:visible').not('.slick-initialized').slick({
            autoplay: true,
            autoplaySpeed: 2000,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            slidesPerRow: 1,
            fade: true,
            centerMode: true,
            adaptiveHeight: true
        });

        //slider clientov

        $('.slides-clients').not('.slick-initialized').slick({
            autoplay: true,
            autoplaySpeed: 0.1,
            infinite: true,
            speed: 4000,
            //speed: 1000, // Testiranje
            centerPadding: '10px',
            cssEase:'linear',
            slidesToShow: 12,
            //arrows:true, //Testiranje
            arrows:false,
            draggable:false,
            pauseOnFocus:false,
            pauseOnHover:false,
            slidesToScroll: 1,
            //slidesToScroll: 10, //Testiranje
            slidesPerRow: 1,
            responsive: [
                {
                    breakpoint: 1700,
                    settings: {
                      slidesToShow: 8,
                      slidesToScroll: 8,
                      speed: 8000,
                      autoplaySpeed: 0.1,
                    }
                },
                {
                    breakpoint: 1500,
                    settings: {
                      slidesToShow: 6,
                      slidesToScroll: 6,
                      speed: 12000,
                    }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        });


    }
};
